import algoliaClient from '/utils/algolia-client.js';
import { DEFAULT_INDEX, DONT_USE_ALGOLIA, FACETS } from './constants';

export const getFacets = async (collectionHandle, additionalFacetFilters, filters) => {
	if (DONT_USE_ALGOLIA) {
		return [];
	}

	let facetFilters = [];
	let term = '';

	if (collectionHandle) {
		facetFilters = [`collections:${collectionHandle}`];
	}

	if (additionalFacetFilters) {
		facetFilters = [...facetFilters, ...additionalFacetFilters];
	}

	const { facets, nbHits } = await algoliaClient.initIndex(DEFAULT_INDEX).search(term, {
		hitsPerPage: 1,
		page: 1,
		filters,
		facetFilters: facetFilters,
		facets: Object.keys(FACETS),
	});

	return { count: nbHits || 0, facets: facets || {} };
};

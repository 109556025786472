const iconMap = {
	arrowLeft: {
		altText: 'Left Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/arrow-left-gray.svg',
	},
	arrowRight: {
		altText: 'Right Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/arrow-right-gray.svg',
	},
	arrowDown: {
		altText: 'Down Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/arrow-down-gray.svg',
	},
	arrowUp: {
		altText: 'Up Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/arrow-up-gray.svg',
	},
	arrowCircleDown: {
		altText: 'Down Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/circle-arrow-down-gray.svg',
	},
	arrowCircleLeft: {
		altText: 'Left Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/circle-arrow-left-gray.svg',
	},
	arrowCircleRight: {
		altText: 'Right Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/circle-arrow-right-gray.svg',
	},
	arrowCircleUp: {
		altText: 'Up Arrow',
		src: 'https://cdn.shinesty.com/2023-01-25/circle-arrow-up-gray.svg',
	},
	cart: {
		altText: 'Cart',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Cart.svg',
	},
	cartCircle: {
		altText: 'Cart',
		src: 'https://cdn.shinesty.com/2023-01-25/circle-cart-icon.svg',
	},
	caretLeft: {
		altText: 'Left Caret',
		src: 'https://cdn.shinesty.com/2023-01-25/carat-left-gray.svg',
	},
	caretRight: {
		altText: 'Right Caret',
		src: 'https://cdn.shinesty.com/2023-01-25/carat-right-gray.svg',
	},
	caretDown: {
		altText: 'Down Caret',
		src: 'https://cdn.shinesty.com/2023-01-25/carat-down-gray.svg',
	},
	caretUp: {
		altText: 'Up Caret',
		src: 'https://cdn.shinesty.com/2023-01-25/carat-up-gray.svg',
	},
	caretDownWhite: {
		altText: 'Down Caret',
		src: 'https://cdn.shinesty.com/2023-11-03/carat-down-white.svg',
	},
	checkmark: {
		altText: 'Check',
		src: 'https://cdn.shinesty.com/2023-07-25/checkmark-outline-green.svg',
	},
	close: {
		altText: 'Close',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Close.svg',
	},
	closeWhite: {
		altText: 'Close',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/CloseWhite.svg',
	},
	closeGray: {
		altText: 'Close',
		src: 'https://cdn.shinesty.com/2022-11-28/CloseGray.svg',
	},
	cloud: {
		altText: 'Cloud',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Cloud.svg',
	},
	collapse: {
		altText: 'Collapse',
		src: 'https://cdn.shinesty.com/2023-01-25/collapse-gray.svg',
	},
	countryFlagUS: {
		altText: 'US Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-US.svg',
	},
	countryFlagAU: {
		altText: 'Australian Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-AU.svg',
	},
	countryFlagAT: {
		altText: 'AT Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-AT.svg',
	},
	countryFlagBE: {
		altText: 'BE Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-BE.svg',
	},
	countryFlagBG: {
		altText: 'BG Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-BG.svg',
	},
	countryFlagCA: {
		altText: 'CA Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-CA.svg',
	},
	countryFlagCY: {
		altText: 'CY Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-CY.svg',
	},
	countryFlagCZ: {
		altText: 'CZ Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-CZ.svg',
	},
	countryFlagDK: {
		altText: 'DK Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-DK.svg',
	},
	countryFlagEE: {
		altText: 'EE Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-EE.svg',
	},
	countryFlagFI: {
		altText: 'FI Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-FI.svg',
	},
	countryFlagFR: {
		altText: 'FR Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-FR.svg',
	},
	countryFlagDE: {
		altText: 'DE Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-DE.svg',
	},
	countryFlagGR: {
		altText: 'GR Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-GR.svg',
	},
	countryFlagHU: {
		altText: 'HU Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-HU.svg',
	},
	countryFlagIE: {
		altText: 'IE Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-IE.svg',
	},
	countryFlagIT: {
		altText: 'IT Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-IT.svg',
	},
	countryFlagLV: {
		altText: 'LV Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-LV.svg',
	},
	countryFlagLT: {
		altText: 'LT Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-LT.svg',
	},
	countryFlagLU: {
		altText: 'LU Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-LU.svg',
	},
	countryFlagMT: {
		altText: 'MT Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-MT.svg',
	},
	countryFlagNL: {
		altText: 'NL Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-NL.svg',
	},
	countryFlagPL: {
		altText: 'PL Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-PL.svg',
	},
	countryFlagPT: {
		altText: 'PT Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-PT.svg',
	},
	countryFlagRO: {
		altText: 'RO Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-RO.svg',
	},
	countryFlagSK: {
		altText: 'SK Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-SK.svg',
	},
	countryFlagSI: {
		altText: 'SI Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-SI.svg',
	},
	countryFlagES: {
		altText: 'ES Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-ES.svg',
	},
	countryFlagSE: {
		altText: 'SE Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-SE.svg',
	},
	countryFlagGB: {
		altText: 'GB Flag',
		src: 'https://cdn.shinesty.com/2023-10-16/countryFlag-GB.svg',
	},
	expand: {
		altText: 'Expand',
		src: 'https://cdn.shinesty.com/2023-01-25/expand-gray.svg',
	},
	facebook: {
		altText: 'Facebook',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Facebook.svg',
	},
	flag: {
		altText: 'Flag',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Flag.svg',
	},
	giftBoxCircleRed: {
		altText: 'Gift box',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/GiftboxCircleRed.svg',
	},
	grabHandleVert: {
		altText: 'Move',
		src: 'https://cdn.shinesty.com/2023-01-25/grab-vertical-gray.svg',
	},
	grabHandleHorz: {
		altText: 'Move',
		src: 'https://cdn.shinesty.com/2023-01-25/grab-horizontal-gray.svg',
	},
	'info-gray': {
		altText: 'info icon',
		src: 'https://cdn.shinesty.com/2023-11-08/info-tip.svg',
	},
	instagram: {
		altText: 'Instagram',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Instagram.svg',
	},
	instagramDark: {
		altText: 'Instagram',
		src: 'https://cdn.shinesty.com/2022-10-17/Instagram.svg',
	},
	leaf: {
		altText: 'Leaf',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Leaf.svg',
	},
	lock: {
		altText: 'Lock',
		src: 'https://cdn.shinesty.com/2023-07-25/lock-outline-gray.svg',
	},
	menu: {
		altText: 'Menu',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/MenuRed.svg',
	},
	minus: {
		altText: 'Minus',
		src: 'https://cdn.shinesty.com/2022-09-09/minus.svg',
	},
	peach: {
		altText: 'Peach',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Peach.svg',
	},
	plus: {
		altText: 'plus sign',
		src: 'https://cdn.shinesty.com/2022-09-09/plus.svg',
	},
	quickshop: {
		altText: 'Quick Shop',
		src: 'https://cdn.shopify.com/s/files/1/0234/5963/t/24/assets/Eye-red.svg?v=2335426057213120004',
	},
	remove: {
		altText: 'Remove',
		src: 'https://cdn.shinesty.com/2023-01-25/remove-red.svg',
	},
	return: {
		altText: 'Return',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Return.svg',
	},
	reverse: {
		altText: 'Reverse',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Reverse.svg',
	},
	search: {
		altText: 'search',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Search.svg',
	},
	shinesty: {
		altText: 'Shinesty',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Shinesty.svg',
	},
	'shinesty-white': {
		altText: 'Shinesty',
		src: 'https://cdn.shinesty.com/2023-11-17/shinesty-white.svg',
	},
	shopPay: {
		altText: 'ShopPay',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/ShopPayIcon.svg',
	},
	shopPayInstallments: {
		altText: 'ShopPay Installments',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/ShopPayInstallmentsIcon.svg',
	},
	sparkle: {
		altText: 'Sparkle',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Sparkle.svg',
	},
	spinner: {
		altText: 'Loading',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/Spinner.svg',
	},
	starEmpty: {
		altText: 'Empty Star',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StarEmpty.svg',
	},
	starFull: {
		altText: 'Full Star',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StarFilled.svg',
	},
	starHalf: {
		altText: 'Half Star',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StarHalf.svg',
	},
	starThird: {
		altText: 'one third Star',
		src: 'https://cdn.shinesty.com/2022-07-08/StarFilled-1_3.svg',
	},
	starTwoThird: {
		altText: 'two third Star',
		src: 'https://cdn.shinesty.com/2022-07-08/StarFilled-2_3.svg',
	},
	stickerBoxerFly: {
		altText: 'Boxer With Fly',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StickerBoxerFly.svg',
	},
	stickerBoxerLong: {
		altText: 'Long Boxers',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StickerBoxerLong.svg',
	},
	stickerBrief: {
		altText: 'Briefs',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StickerBrief.svg',
	},
	stickerFur: {
		altText: 'Fur Underwear',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StickerFur.svg',
	},
	stickerNew: {
		altText: 'New Item',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StickerNew.svg',
	},
	stickerPolarPair: {
		altText: 'Polar Pair Cooling Underwear',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StickerPolarPair.svg',
	},
	stickerTrunk: {
		altText: 'Trunks',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/StickerTrunk.svg',
	},
	trashGray: {
		altText: 'Trash',
		src: 'https://cdn.shinesty.com/2023-01-25/trash-gray.svg',
	},
	trashRed: {
		altText: 'Trash',
		src: 'https://cdn.shinesty.com/2023-01-25/trash-red.svg',
	},
	twitter: {
		altText: 'Twitter',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/TwitterWhite.svg',
	},
	underwearBikiniFilled: {
		altText: 'Bikini Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/bikini-filled.svg',
	},
	underwearBikiniOutline: {
		altText: 'Bikini Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/bikini-line.svg',
	},
	underwearBikiniOutlineWhite: {
		altText: 'Bikini Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/bikini-line-white.svg',
	},
	underwearBoxerFilled: {
		altText: 'Boxer Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/standard-filled.svg',
	},
	underwearBoxerOutline: {
		altText: 'Boxer Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/standard-line.svg',
	},
	underwearBoxerOutlineWhite: {
		altText: 'Boxer Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/standard-line-white.svg',
	},
	underwearBoxerBriefFilled: {
		altText: 'Boxer Brief Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/brief-filled.svg',
	},
	underwearBoxerBriefOutline: {
		altText: 'Boxer Brief Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/brief-line.svg',
	},
	underwearBoxerBriefOutlineWhite: {
		altText: 'Boxer Brief Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/brief-line-white.svg',
	},
	underwearBoxerCoolingFilled: {
		altText: 'Boxer Cooling Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/cooling-filled.svg',
	},
	underwearBoxerCoolingOutline: {
		altText: 'Boxer Cooling Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/cooling-line.svg',
	},
	underwearBoxerCoolingOutlineWhite: {
		altText: 'Boxer Cooling Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/cooling-line-white.svg',
	},
	underwearBoxerFlyFilled: {
		altText: 'Boxer Fly Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/with-fly-filled.svg',
	},
	underwearBoxerFlyOutline: {
		altText: 'Boxer Fly Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/with-fly-line.svg',
	},
	underwearBoxerFlyOutlineWhite: {
		altText: 'Boxer Fly Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/with-fly-line-white.svg',
	},
	underwearBoxerLongFilled: {
		altText: 'Boxer Long Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/long-filled.svg',
	},
	underwearBoxerLongOutline: {
		altText: 'Boxer Long Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/long-line.svg',
	},
	underwearBoxerLongOutlineWhite: {
		altText: 'Boxer Long Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/long-line-white.svg',
	},
	underwearBoxerTrunksFilled: {
		altText: 'Trunks Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/trunks-filled.svg',
	},
	underwearBoxerTrunksOutline: {
		altText: 'Trunks Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/trunks-line.svg',
	},
	underwearBoxerTrunksOutlineWhite: {
		altText: 'Trunks Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/trunks-line-white.svg',
	},
	underwearBoyshortFilled: {
		altText: 'Boyshort Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/boyshort-filled.svg',
	},
	underwearBoyshortOutline: {
		altText: 'Boyshort Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/boyshort-line.svg',
	},
	underwearBoyshortOutlineWhite: {
		altText: 'Boyshort Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/boyshort-line-white.svg',
	},
	underwearCheekyFilled: {
		altText: 'Cheeky Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/cheeky-filled.svg',
	},
	underwearCheekyOutline: {
		altText: 'Cheeky Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/cheeky-line.svg',
	},
	underwearCheekyOutlineWhite: {
		altText: 'Cheeky Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/cheeky-line.svg',
	},
	underwearThongFilled: {
		altText: 'Thong Filled',
		src: 'https://cdn.shinesty.com/2022-06-22/thong-filled.svg',
	},
	underwearThongOutline: {
		altText: 'Thong Outline',
		src: 'https://cdn.shinesty.com/2022-06-22/thong-line.svg',
	},
	underwearThongOutlineWhite: {
		altText: 'Thong Outline',
		src: 'https://cdn.shinesty.com/2022-07-20/thong-line-white.svg',
	},
	underwearWBoxerFilled: {
		altText: 'Boxer Icon',
		src: 'https://cdn.shinesty.com/2024-04-03/underwearWBoxerSolid.svg',
	},
	underwearWBoxerOutline: {
		altText: 'Boxer Outline Icon',
		src: 'https://cdn.shinesty.com/2024-04-03/underwearWBoxerOutline.svg',
	},
	underwearWBoxerOutlineWhite: {
		altText: 'Boxer Outline Icon',
		src: 'https://cdn.shinesty.com/2024-04-03/underwearWBoxerOutlineWhite.svg',
	},
	user: {
		altText: 'Account',
		src: 'https://d3w0qy4ktfyhb7.cloudfront.net/temporary/2022-03-19-icons/User.svg',
	},
	ballhallaPlanet: {
		altText: 'Spacious & Supportive',
		src: 'https://cdn.shinesty.com/2022-09-28/planet-lp-test.svg',
	},
	ballhallaCloud: {
		altText: '3X Softer Than Cotton',
		src: 'https://cdn.shinesty.com/2022-09-28/cloud-lp-test.svg',
	},
	ballhallaDrops: {
		altText: '3X Softer Than Cotton',
		src: 'https://cdn.shinesty.com/2022-09-28/drops-lp-test.svg',
	},
	ballhallaPattern: {
		altText: '3X Softer Than Cotton',
		src: 'https://cdn.shinesty.com/2022-09-28/pattern-lp-test.svg',
	},
};

export default iconMap;

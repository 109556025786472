const BASIC_PDP_CONFIG = {
	defaultDetailsDrawerOpen: false,
	showCustomersAlsoBought: true,
	showCrossSell: true,
	showRecommendedProducts: true,
	showReviews: true,
};

const DEFAULT_PDP_CONFIG = {
	...BASIC_PDP_CONFIG,
	detailsComponent: 'h3',
	imageHeight: 500,
	showExpandableDetails: true,
};

const BOXER_ICONS = [
	{
		text: 'Ball Hammock® Pouch',
		image: 'https://cdn.shinesty.com/2023-11-10/ball-hammock-eggs-icon.svg',
	},
	{
		text: 'Sumptuously Soft MicroModal',
		image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
	},
	{
		text: 'Moisture Wicking + Breathable',
		image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
	},
	{
		text: "Love Your First Pair or They're Free",
		image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
	},
];

const TSHIRT_ICONS = [
	{
		text: 'Proprietary Super Stupid-Soft Fabric Blend',
		image: 'https://cdn.shinesty.com/2024-07-22/Feather.svg',
	},
	{
		text: "Everyman Fit, Flattering for Men Who Don't Count Carbs",
		image: 'https://cdn.shinesty.com/2024-07-22/Tshirt Fit.svg',
	},
	{
		text: 'Love Your First Tee or Its Free',
		image: 'https://cdn.shinesty.com/2024-07-22/PiggyBank.svg',
	},
];

const productTypeConfigs = {
	'laundry detergent sheets': {
		defaultDetailsDrawerOpen: true,
		imageHeight: 750,
		imagesToShow: 4,
		isLaundry: true,
		showCustomersAlsoBought: true,
		showCrossSell: false,
		showRecommendedProducts: false,
		showReviews: true,
	},
	bikini: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/bikini-rise-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'bikini lace': {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: [
			{
				text: 'Sumptuously Soft Nylon Spandex Blend',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/bikini-rise-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	boxers: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	'boxers - brief': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	'boxers - fly': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	'boxers - long - fly': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	'boxers - cooling - fly': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Next Level Wicking + Breathability',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: 'Keeps Skin 3° Cooler',
				image: 'https://cdn.shinesty.com/2023-11-10/cooling-snowflake-icon.svg',
			},
			{
				text: 'Ball Hammock Support',
				image: 'https://cdn.shinesty.com/2023-11-10/ball-hammock-eggs-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'boxers - trunk': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	boyshort: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Full Coverage + Mid-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/boyshort-rise-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	bralettes: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Lifts & Supports The Girls',
				image: 'https://cdn.shinesty.com/2023-11-10/boob-lift-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'bralettes - cooling': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Next Level Wicking + Breathability',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: 'Keeps Skin 3° Cooler',
				image: 'https://cdn.shinesty.com/2023-11-10/cooling-snowflake-icon.svg',
			},
			{
				text: 'Lifts & Supports The Girls',
				image: 'https://cdn.shinesty.com/2023-11-10/boob-lift-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	cheeky: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/cheeky-rise-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	"men's ss knits": {
		defaultDetailsDrawerOpen: false,
		imageHeight: 700,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Insanely soft, stretchy fabric',
				image: 'https://cdn.shinesty.com/2023-11-10/fabric-lace-icon.svg',
			},
			{
				text: 'Perfect tailored fit',
				image: 'https://cdn.shinesty.com/2024-05-08/tapered-fit-polo.svg',
			},
			{
				text: "Love it or it's free guarantee",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'socks - ankle': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/just-right-measure-icon.svg',
			},
			{
				text: 'Seamless Toe',
				image: 'https://cdn.shinesty.com/2023-11-10/seamless-toe-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'socks - ankle - pack': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/just-right-measure-icon.svg',
			},
			{
				text: 'Seamless Toe',
				image: 'https://cdn.shinesty.com/2023-11-10/seamless-toe-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'socks - crew': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/just-right-measure-icon.svg',
			},
			{
				text: 'Seamless Toe',
				image: 'https://cdn.shinesty.com/2023-11-10/seamless-toe-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'socks - crew - pack': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/just-right-measure-icon.svg',
			},
			{
				text: 'Seamless Toe',
				image: 'https://cdn.shinesty.com/2023-11-10/seamless-toe-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'cheeky - top lace': {
		...DEFAULT_PDP_CONFIG,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal & Lace',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/cheeky-rise-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'long johns': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 750,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	"men's shorts": {
		defaultDetailsDrawerOpen: false,
		showCustomersAlsoBought: true,
		imageHeight: 750,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Ball Hammock Support',
				image: 'https://cdn.shinesty.com/2023-11-10/ball-hammock-eggs-icon.svg',
			},
			{
				text: 'Extremely light weight cooling fabric',
				image: 'https://cdn.shinesty.com/2023-11-10/cooling-snowflake-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	"men's t-shirts": {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: TSHIRT_ICONS,
	},
	"men's t-shirts - pack": {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: TSHIRT_ICONS,
	},
	thongs: {
		...DEFAULT_PDP_CONFIG,
		pdpIcons: [
			{
				text: 'Low Coverage + Low-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/thong-rise-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'thongs - cooling': {
		...DEFAULT_PDP_CONFIG,
		pdpIcons: [
			{
				text: 'Next Level Wicking + Breathability',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: 'Keeps Skin 3° Cooler',
				image: 'https://cdn.shinesty.com/2023-11-10/cooling-snowflake-icon.svg',
			},
			{
				text: 'Low Coverage + Low-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/thong-rise-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	'thongs - modal': {
		defaultDetailsDrawerOpen: false,
		detailsComponent: 'h3',
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: 'Low Coverage + Mid-Rise Fit',
				image: 'https://cdn.shinesty.com/2023-11-10/thong-rise-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	trunks: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Ball Hammock Support',
				image: 'https://cdn.shinesty.com/2023-11-10/ball-hammock-eggs-icon.svg',
			},
			{
				text: 'Light Weight, Quick Dry Fabric',
				image: 'https://cdn.shinesty.com/2023-11-10/quick-dry-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
	"women's boxer": {
		defaultDetailsDrawerOpen: false,
		detailsComponent: 'h3',
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg',
			},
			{
				text: 'Boxer Style Cut',
				image: 'https://cdn.shinesty.com/2024-04-03/underwearWBoxerOutline.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg',
			},
		],
	},
};

function getProductPageConfig(product) {
	const productType = product.productType || product.shopifyProductType;
	const foundPdpConfig = productTypeConfigs[productType.toLowerCase()];
	return foundPdpConfig || BASIC_PDP_CONFIG;
}

module.exports = {
	getProductPageConfig,
};

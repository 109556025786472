import cartServices from '/services/cart';
import { getPackDiscount } from '../services/static/packs/pack-price';
import { getTestPrice } from '/services/pricing/pricing-static';
import { getTestVariant } from '/services/ab-test';
import { init as initLoopCart } from '/services/loop-returns';
import { linesFromVariants } from '/utils/product';

import {
	ADD_TO_CART_TIME,
	BUILD_A_PACK,
	CAN_CHANGE_QUANTITY,
	DISCOUNTED_LINE_PRICE,
	IS_SUBSCRIPTION,
	LINE_PRICE,
	PRICE_TEST,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_TIER,
	SHIPPING_TEST_VARIANT,
	SITEWIDE_PACK_BUILDER,
} from '/services/static/attribute-keys';

import { checkPromotionalInventory, runPromotions } from '/services/static/promotions';
import { makeAutoObservable, toJS } from 'mobx';
import { readLocalStorage, removeLocalStorageValue, writeLocalStorage } from '/utils/local-storage';
import { trackAddToCart, trackCartEmpty } from '/services/analytics/shinesty-analytics';

const CART_ID_KEY = 'shinesty:shopify:cartId';

const addAddToCartTime = (line) => {
	if (!line.attributes) {
		line.attributes = [];
	}

	const addToCartTimeAttribute = line.attributes.find((attribute) => {
		return attribute.key === ADD_TO_CART_TIME;
	});

	if (!addToCartTimeAttribute) {
		line.attributes.push({ key: ADD_TO_CART_TIME, value: new Date().toISOString() });
	}

	return line;
};

function checkLineForSubscriptionOrPack(existingLine) {
	return existingLine?.attributes?.find((attribute) => {
		return attribute.key === IS_SUBSCRIPTION || attribute.key === BUILD_A_PACK;
	});
}

export class CartStore {
	discountGroup = '';
	freeShippingVariant = '';
	drawerOpen = false;
	modalOpen = false;
	initialized = false;
	shopifyCart = {};
	promotionalProducts = [];
	promotionsLoading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async addCartAttributes(attributes) {
		const cartId = readLocalStorage(CART_ID_KEY);
		if (cartId) {
			await cartServices.addCartAttributes(cartId, attributes);
		}
	}

	async getDiscountTestVariant() {
		if (this.discountGroup != '') {
			return toJS(this.discountGroup);
		}

		const variant = await getTestVariant('pack-price-test-2024');

		if (variant.key) {
			this.discountGroup = variant.key;
			return variant.key;
		}
	}

	async getShippingTestVariant() {
		if (this.freeShippingVariant !== '') {
			return toJS(this.freeShippingVariant);
		}

		const variant = await getTestVariant('free-shipping-2024');

		if (variant.key) {
			this.freeShippingVariant = variant.key;
			return variant.key;
		}
	}

	async clearCart() {
		const cartId = readLocalStorage(CART_ID_KEY);
		if (cartId) {
			await this.loadCart();

			const lineIds = this.shopifyCart?.lines?.edges?.reduce((memo, edge) => {
				memo.push(edge?.node?.id);
				return memo;
			}, []);

			await cartServices.removeItemsFromCart(cartId, lineIds);

			removeLocalStorageValue(CART_ID_KEY);
		}
	}
	//keep cart id but remove all items
	async emptyCart() {
		const cartId = readLocalStorage(CART_ID_KEY);
		if (!cartId) {
			return;
		}

		const lineIds = this.shopifyCart?.lines?.edges?.reduce((memo, edge) => {
			memo.push(edge?.node?.id);
			return memo;
		}, []);

		await this.removeItems({ lineIds });
	}

	async createLoopCart(loopCartData) {
		await this.clearCart();
		await this.addItems({ lines: loopCartData });
	}

	/**
	 * @param {*} settings - object { lines, analytics, success, error, skipPromotions }
	 * 		- lines - lines to add
	 * 		- analytics - analytics data
	 * 		- success - success function callback
	 * 		- error - error function callback
	 * 		- skipPromotions - don't run promotional functionality
	 */
	async addItems(settings) {
		let cartId = readLocalStorage(CART_ID_KEY);

		// there's no cart id in local storage, create a cart
		if (!cartId) {
			const data = await cartServices.createCart();
			if (data.cartCreate && data.cartCreate.cart) {
				cartId = data.cartCreate.cart.id;
				writeLocalStorage(CART_ID_KEY, cartId);
				// TODO: swap for js-cookie
				document.cookie = `cart=${cartId}; path=/`;
			}
		} else {
			// there is a cart id in local storage, try to load the cart
			// from the server to ensure it exists
			await this.loadCart();
			if (!this.shopifyCart) {
				return this.addItems(settings);
			}
		}

		// a map of merchandise id to cart line id and quantity
		const existingVariantMap =
			this.shopifyCart?.lines?.edges?.reduce((memo, edge) => {
				memo[edge?.node?.merchandise?.id || ''] = {
					lineId: edge?.node?.id || '',
					quantity: edge?.node?.quantity || 0,
					attributes:
						edge?.node?.attributes.map((attribute) => {
							return { key: attribute?.key, value: attribute?.value };
						}) || [],
				};

				return memo;
			}, {}) || {};

		// sort the lines to new lines (new product) and existing lines (update quantity)
		const sortedLines = settings.lines.reduce(
			(memo, line) => {
				const existingLine = existingVariantMap[line.merchandiseId];

				// if the new line is a subscription, always add it
				if (checkLineForSubscriptionOrPack(line)) {
					line = addAddToCartTime(line);
					memo.newLines.push(line);
					return memo;
				}

				if (
					existingLine && // there's an exsiting line iwth the same variant
					!checkLineForSubscriptionOrPack(existingLine) // the existing line isn't sub/pack
				) {
					memo.existingLines.push({
						id: existingVariantMap[line.merchandiseId].lineId,
						quantity: line.quantity + existingVariantMap[line.merchandiseId].quantity,
					});
					return memo;
				}

				if (!line.attributes) {
					line.attributes = [];
				}

				line = addAddToCartTime(line);

				const addedProduct = settings?.analytics[0]?.product;

				if (addedProduct) {
					const discountedPrice = getTestPrice(addedProduct, this.discountGroup);
					if (discountedPrice) {
						line.attributes.push({ key: DISCOUNTED_LINE_PRICE, value: String(discountedPrice) });
						line.attributes.push({ key: LINE_PRICE, value: String(discountedPrice) });
						line.attributes.push({ key: PRICE_TEST, value: 'true' });
					}
					if (this.freeShippingVariant !== '') {
						line.attributes.push({ key: SHIPPING_TEST_VARIANT, value: this.freeShippingVariant });
					}
				}
				memo.newLines.push(line);

				return memo;
			},
			{ existingLines: [], newLines: [] },
		);

		// a sucessful add to cart.
		const success = async () => {
			const cart = await this.loadCart();

			if (settings.success) {
				await settings.success();
			}

			if (settings.analytics) {
				trackAddToCart(
					settings.lines,
					settings.analytics,
					settings.analyticsProduct,
					cart.checkoutUrl,
					cart.id,
				);
			}

			if (settings?.lines?.length <= 2 && !this.drawerOpen) {
				this.setModalOpen(true);
			} else {
				this.drawerOpen = true;
			}

			if (settings.skipPromotions !== true) {
				await this.updateCartWithPromotions();
			}

			return this.shopifyCart;
		};

		// add item to cart
		if (sortedLines.newLines.length > 0) {
			const addResponse = await cartServices.addItemsToCart(cartId, sortedLines.newLines);

			if (addResponse?.cartLinesAdd?.userErrors?.length > 0) {
				if (settings.error) {
					settings.error();
				}
			} else {
				await success();
			}
		}

		if (sortedLines.existingLines.length > 0) {
			const updateResponse = await cartServices.updateItemsInCart(
				cartId,
				sortedLines.existingLines,
				success,
				settings.error,
			);

			if (updateResponse?.cartLinesUpdate?.userErrors?.length > 0) {
				if (settings.error) {
					settings.error();
				}
			} else {
				await success();
			}
		}
	}

	/**
	 * Used for wunderkind to prepopulate an abandoned cart from the querystring
	 * @param {object} query the querystring
	 */
	async initQueryCart(queryCartId, query) {
		const cartId = readLocalStorage(CART_ID_KEY);

		const loopCartData = await initLoopCart(query);

		if (loopCartData) {
			await this.createLoopCart(loopCartData);
			return;
		}

		if (!cartId && queryCartId) {
			writeLocalStorage(CART_ID_KEY, queryCartId);
			await this.loadCart();
		}
	}

	async updateCartWithPromotions() {
		this.promotionsLoading = true;
		const promotionUpdates = await runPromotions(this.plain());

		if (promotionUpdates) {
			await this.promotionalItemsRemove(promotionUpdates.lineItemsToRemove);
			await this.promotionalItemsAdd(promotionUpdates.promotionalProductsToAdd);
		}

		this.promotionsLoading = false;
	}

	async loadCart() {
		const cartId = readLocalStorage(CART_ID_KEY);

		if (!cartId) {
			return;
		}
		const data = await cartServices.getCart(cartId);

		// the server returned a cart, save it.
		if (data && data.cart) {
			if (!this.initialized) {
				this.updateCart(data.cart);
				this.setInitialized(true);
				// await this.updateCartWithPromotions();
			}
			return this.updateCart(data.cart);
		} else {
			// the cart is corrupt, remove it from local storage
			removeLocalStorageValue(CART_ID_KEY);
		}
	}

	async promotionalItemsAdd(promotionalProducts) {
		if (!promotionalProducts) {
			return;
		}

		// automatically add any products that (1) aren't selectable and (2) dont have options
		if (promotionalProducts.autoAddTier.length > 0) {
			const promises = promotionalProducts.autoAddTier.map((tier) => {
				return checkPromotionalInventory(tier);
			});

			// an array of all tiers, checked and their backups
			const checkedTiers = await Promise.all(promises);

			// sort checked products into autoAdd / interactionRequired
			const storted = checkedTiers.reduce(
				(memo, checkedTier) => {
					if (checkedTier.interactionRequired) {
						memo.interactionRequired.push(checkedTier.tier);
					} else {
						memo.autoAdd = [...memo.autoAdd, ...checkedTier.autoAdd];
					}
					return memo;
				},
				{ autoAdd: [], interactionRequired: [] },
			);

			promotionalProducts.autoAdd = storted.autoAdd;
			if (storted.interactionRequired.length > 0) {
				promotionalProducts.interactionRequired = [
					...storted.interactionRequired,
					...promotionalProducts.interactionRequired,
				];
			}

			const existingItems = this.shopifyCart?.lines?.edges?.map((edge) => {
				return edge?.node?.merchandise?.id;
			});

			const variants = promotionalProducts.autoAdd.reduce((memo, promotionalProduct) => {
				// short circut adding an existing promoitional item to the cart
				if (existingItems.includes(promotionalProduct.variantId)) {
					return memo;
				}

				memo.push({
					attributes: [
						{ key: CAN_CHANGE_QUANTITY, value: 'false' },
						{ key: PROMOTIONAL_ITEM, value: 'true' },
						{ key: PROMOTIONAL_TIER, value: promotionalProduct.tierTitle },
					],
					variantId: promotionalProduct.variantId,
					quantity: parseInt(promotionalProduct.quantity),
				});
				return memo;
			}, []);
			const lines = linesFromVariants(variants);
			await this.addItems({ lines, skipPromotions: true });
		}

		if (promotionalProducts.interactionRequired.length > 0) {
			this.updatePromotionalproducts(promotionalProducts.interactionRequired);
		} else {
			this.updatePromotionalproducts([]);
		}
	}

	async promotionalItemsRemove(lineIds) {
		if (!lineIds || lineIds.length < 1) {
			return;
		}
		await this.removeItems({ lineIds, skipPromotions: true });
	}

	/**
	 * @param {object} settings {lineIds, skipPromotions}
	 * @returns the shopify cart
	 */
	async removeItems(settings) {
		const cartId = readLocalStorage(CART_ID_KEY);

		if (!cartId || !settings.lineIds || settings.lineIds.length === 0) {
			return this.shopifyCart;
		}

		await cartServices.removeItemsFromCart(cartId, settings.lineIds);
		await this.loadCart();

		if (settings.skipPromotions !== true) {
			await this.updateCartWithPromotions();
		}

		if (this.shopifyCart?.lines?.edges?.length === 0) {
			trackCartEmpty();
		}

		return this.shopifyCart;
	}

	async removeSubscriptions() {
		const lineItems = this.shopifyCart?.lines;

		if (!lineItems?.edges) {
			return;
		}

		const subscriptionLineItemIds = lineItems.edges.reduce((memo, lineItem) => {
			const isSubscriptionParent = lineItem.node?.attributes.find((attribute) => {
				return attribute.key === '__isSubscriptionParent' && attribute.value === 'true';
			});

			const isSubscriptionChild = lineItem.node?.attributes.find((attribute) => {
				return attribute.key === '__isSubscriptionChild' && attribute.value === 'true';
			});

			if (isSubscriptionParent || isSubscriptionChild) {
				memo.push(lineItem.node.id);
			}
			return memo;
		}, []);

		if (subscriptionLineItemIds.length === 0) {
			return;
		}

		await this.removeItems({
			lineIds: subscriptionLineItemIds,
		});
	}

	async updateItems(lines, skipPromotions = false) {
		const cartId = readLocalStorage(CART_ID_KEY);
		if (cartId) {
			await cartServices.updateItemsInCart(cartId, lines);
			await this.loadCart();

			if (skipPromotions !== true) {
				await this.updateCartWithPromotions();
			}

			return this.shopifyCart;
		}
	}

	updateCart(newCart) {
		this.shopifyCart = { ...newCart };
		return this.shopifyCart;
	}

	updatePromotionalproducts(tiers) {
		this.promotionalProducts = [...tiers];
		return this.promotionalProducts;
	}

	getNextPackDiscount() {
		let count = 0;
		this.shopifyCart.lines?.edges.map((item) => {
			if (item.node.attributes.find((item) => item.key === SITEWIDE_PACK_BUILDER)) {
				count++;
			}
		});

		return getPackDiscount('underwear', count);
	}

	setInitialized(val) {
		this.initialized = val;
		return this.initialized;
	}

	setDrawerOpen(val) {
		this.drawerOpen = val;
		return this.drawerOpen;
	}
	setModalOpen(val) {
		this.modalOpen = val;
		return this.drawerOpen;
	}

	plain() {
		return toJS(this);
	}
}

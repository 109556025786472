import PropTypes from 'prop-types';
import { formatMonetaryValue } from '/utils/format';
import { getTestPrice } from '/services/pricing/pricing-static';
import { observer } from 'mobx-react-lite';
import { useCartStore } from '/state/cart-context';
import { useGlobalStore } from '../state/global-context';
import { convertValue, getPrice } from '/services/openborder';
import { useEffect, useState } from 'react';

const Price = ({
	// short or explicit, documentation here:
	// https://polaris.shopify.com/foundations/formatting-localized-currency#major-currencies-in-their-local-formats
	format = 'short',

	// hash of country to value to short circuit the api call
	internationalPricing,

	// price to convert
	price,

	product,

	// if the variant is selected, use the variant api instead of the conversion api
	selectedVariant,

	// if the country is US, and we want to force the symbol, set to true
	showSymbol = false,
}) => {
	const globalStore = useGlobalStore();
	const cartStore = useCartStore();

	const [formattedPrice, setFormattedPrice] = useState(formatMonetaryValue(price));
	const currentCountry = globalStore.getCurrentCountry();
	const formattedVariant = selectedVariant?.replace('gid://shopify/ProductVariant/', '');

	useEffect(() => {
		const updatePrice = async () => {
			if (!currentCountry || currentCountry.currencyCode === 'USD') {
				const testVariant = await cartStore.getDiscountTestVariant();
				const testPrice = getTestPrice(product, testVariant);

				if (testPrice) {
					setFormattedPrice(formatMonetaryValue(testPrice, currentCountry));
				} else {
					setFormattedPrice(formatMonetaryValue(price, currentCountry));
				}
				return;
			}

			// the international pricing dictionary already has the price, no need to call the conversion api
			if (internationalPricing?.[currentCountry.countryCode]?.value) {
				setFormattedPrice(
					formatMonetaryValue(
						internationalPricing?.[currentCountry.countryCode]?.value,
						currentCountry,
					),
				);
				return;
			}

			// call the variant api if a variant id is passed
			if (formattedVariant) {
				const { data } = await getPrice(currentCountry.countryCode, formattedVariant);
				if (data.result?.[currentCountry.countryCode]?.productVariants?.[formattedVariant]?.price) {
					setFormattedPrice(
						formatMonetaryValue(
							data.result?.[currentCountry.countryCode]?.productVariants?.[formattedVariant]?.price,
							currentCountry,
						),
					);
					return;
				}
			}

			// we do not have a pre-converted price, try to convert it over the conversion api
			try {
				if (price) {
					const updatedPrice = await convertValue('USD', price, currentCountry);

					if (updatedPrice && updatedPrice.value) {
						setFormattedPrice(formatMonetaryValue(updatedPrice.value, currentCountry));
					} else {
						setFormattedPrice();
					}
				}
			} catch (e) {
				console.log('openborder - malformed response from convert api, clearing price');
				console.log(e);
				setFormattedPrice();
			}
		};

		updatePrice();
	}, [currentCountry, selectedVariant, price]);

	if (!formattedPrice) {
		return '--';
	}

	if (currentCountry.countryCode === 'US') {
		if (showSymbol) {
			return `$${formattedPrice}`;
		}

		return formattedPrice;
	}

	if (format === 'short') {
		if (currentCountry.reverseCurrencyCode) {
			return `${formattedPrice} ${currentCountry.currencySymbol}`;
		}

		return `${currentCountry.currencySymbol}${formattedPrice}`;
	}

	if (format === 'explicit') {
		if (currentCountry.reverseCurrencyCode) {
			return `${formattedPrice} ${currentCountry.currencySymbol} ${currentCountry.currencyCode}`;
		}

		return `${currentCountry.currencySymbol}${formattedPrice} ${currentCountry.currencyCode}`;
	}

	// if all above fails, return the default formatted price
	return formattedPrice || formatMonetaryValue(price);
};

Price.propTypes = {
	format: PropTypes.oneOf(['explicit', 'short']),
	internationalPricing: PropTypes.object,
	price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	selectedVariant: PropTypes.string,
	showSymbol: PropTypes.bool,
};

export default observer(Price);

import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getSimilarPrints } from '/services/algolia';
import { useEffect, useState } from 'react';

export default function ProductCardPrintSelect({ heapLocation, product, updateProduct }) {
	const [printOptions, setPrintOptions] = useState([]);
	const [activeFlavor, setActiveFlavor] = useState();

	useEffect(() => {
		let print = product.tags.find((tag) => tag.includes('print'));
		if (Array.isArray(print)) {
			print = print[0];
		}
		setActiveFlavor(print);
	}, [product]);

	useEffect(() => {
		async function getPrints() {
			const result = await getSimilarPrints(product, activeFlavor);
			result.unshift(product);
			setPrintOptions(result);
		}
		if (activeFlavor || product.shopifyProductType == "men's t-shirts") {
			getPrints();
		}
	}, [activeFlavor, product]);

	let count = 0;
	const limit = 5;

	if (heapLocation == 'algolia-autocomplete' || product?.handle?.includes('pack')) {
		return null;
	}
	return (
		<div className="flex w-full gap-2 pb-1 pt-2">
			{printOptions.map((print, i) => {
				if (print.handle == printOptions[0].handle && i > 0) {
					return null;
				}
				if (print?.auxiliaryImages?.buttonSrc && count < limit) {
					count++;
					return (
						<Clickable
							key={i}
							onClick={() => updateProduct(print)}
							type="button"
							className={classNames(
								'rounded-full h-[22px] w-[22px]  lg:h-[32px] lg:w-[32px] hover:border-2 border-white hover:ring-gray-600 hover:ring-4',
								product.handle == print.handle ? 'ring-gray-600 ring-4 border-white border-2' : '',
								count > 3 ? 'hidden lg:block' : '',
							)}>
							<Image
								src={print.auxiliaryImages?.buttonSrc}
								height={200}
								width={200}
								objectFit="contain"
								alt="button image"
								className="rounded-full"
							/>
						</Clickable>
					);
				}
			})}
			{printOptions.length > 2 && (
				<div className="flex items-center justify-center">
					<Clickable linkToInternal={'/products/' + product.handle}>
						<Typography className="font-semibold text-secondary-dark" variant="body">
							+{printOptions.length}
						</Typography>
					</Clickable>
				</div>
			)}
		</div>
	);
}

ProductCardPrintSelect.propTypes = {
	heapLocation: PropTypes.string,
	product: PropTypes.object,
	updateProduct: PropTypes.func,
};

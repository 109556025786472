const CONFIG_MONEY_BACK = {
	tiers: [
		{
			moneyBack: 0.2,
			name: 'Tier 1',
			priceMin: 50,
		},
		{
			moneyBack: 0.3,
			name: 'Tier 2',
			priceMin: 100,
		},
		{
			moneyBack: 0.4,
			name: 'Tier 3',
			priceMin: 200,
		},
		{
			moneyBack: 0.5,
			name: 'Tier 4',
			priceMin: 300,
		},
	],
	promotionType: 'money-back',
};

module.exports = {
	CONFIG_MONEY_BACK,
};

const newPackTypes = [
	'Athletic Shorts - Pack',
	'Bikini - Pack',
	'Boxer - Brief - Pack',
	'Boxer - Cooling - Pack',
	'Boxer - Fly - Pack',
	'Boxer - Long - Pack',
	'Boxer - Pack',
	'Boxer - Trunk - Pack',
	'Boyshort - Pack',
	'Bralette - Pack',
	'Cheeky - Pack',
	'Hygiene - Pack',
	'Long Johns - Pack',
	"Men's T-Shirts - Pack",
	'Socks - Ankle - Pack',
	'Socks - Crew - Pack',
	'Thongs - Cooling - Pack',
	'Thongs - Modal - Pack',
	'Thongs - Pack',
	"Women's Boxer - Pack",
];

export const getNewPackProductTypes = () => {
	return newPackTypes;
};

const PRODUCT_TYPE_BIKINI = 'Bikini';
const PRODUCT_TYPE_BOXER = 'Boxer';
const PRODUCT_TYPE_BOXER_BRIEF = 'Boxer - Brief';
const PRODUCT_TYPE_BOXER_FLY = 'Boxer - Fly';
const PRODUCT_TYPE_BOXER_LONG_FLY = 'Boxer - Long - Fly';
const PRODUCT_TYPE_BOXER_TRUNK = 'Boxer - Trunk';
const PRODUCT_TYPE_BOXERS = 'Boxers';
const PRODUCT_TYPE_BOXERS_BRIEF = 'Boxers - Brief';
const PRODUCT_TYPE_BOXERS_COOLING = 'Boxers - Cooling - Fly';
const PRODUCT_TYPE_BOXERS_FLY = 'Boxers - Fly';
const PRODUCT_TYPE_BOXERS_LONG_FLY = 'Boxers - Long - Fly';
const PRODUCT_TYPE_BOXERS_TRUNK = 'Boxers - Trunk';
const PRODUCT_TYPE_BOYSHORT = 'Boyshort';
const PRODUCT_TYPE_CHEEKY = 'Cheeky';
const PRODUCT_TYPE_LAUNDRY_SHEETS = 'Laundry Detergent Sheets';
const PRODUCT_TYPE_SOCKS_ANKLE = 'Socks - Ankle';
const PRODUCT_TYPE_SOCKS_CREW = 'Socks - Crew';
const PRODUCT_TYPE_THONGS = 'Thongs';
const PRODUCT_TYPE_THONGS_COOLING = 'Thongs - Cooling';
const PRODUCT_TYPE_THONGS_MODAL = 'Thongs - Modal';
const PRODUCT_TYPE_T_SHIRTS = "Men's T-Shirts";
const PRODUCT_TYPE_WOMENS_BOXER = "Women's Boxer";

const PRODUCT_TYPES = [
	{ id: PRODUCT_TYPE_BOXERS, title: 'Boxers', titleShort: 'No Fly' },
	{ id: PRODUCT_TYPE_BOXERS_FLY, title: 'Boxers - Fly', titleShort: 'With Fly' },
	{ id: PRODUCT_TYPE_BOXERS_LONG_FLY, title: 'Boxers - Fly - Long', titleShort: 'Long' },
	{ id: PRODUCT_TYPE_BOXERS_BRIEF, title: 'Boxers - Brief', titleShort: 'Briefs' },
	{ id: PRODUCT_TYPE_BOXERS_TRUNK, title: 'Boxers - Trunk', titleShort: 'Trunks' },
	{ id: PRODUCT_TYPE_BOXER, title: 'Boxers', titleShort: 'No Fly' },
	{ id: PRODUCT_TYPE_BOXER_FLY, title: 'Boxers - Fly', titleShort: 'With Fly' },
	{ id: PRODUCT_TYPE_BOXER_LONG_FLY, title: 'Boxers - Fly - Long', titleShort: 'Long' },
	{ id: PRODUCT_TYPE_BOXER_BRIEF, title: 'Boxers - Brief', titleShort: 'Briefs' },
	{ id: PRODUCT_TYPE_BOXER_TRUNK, title: 'Boxers - Trunk', titleShort: 'Trunks' },
	{ id: PRODUCT_TYPE_BOYSHORT, title: 'Boyshort', titleShort: 'Boyshort' },
	{ id: PRODUCT_TYPE_BIKINI, title: 'Bikini', titleShort: 'Bikini' },
	{ id: PRODUCT_TYPE_CHEEKY, title: 'Cheeky', titleShort: 'Cheeky' },
	{ id: PRODUCT_TYPE_LAUNDRY_SHEETS, title: 'Laundry Sheets', titleShort: 'Laundry Sheets' },
	{ id: PRODUCT_TYPE_THONGS, title: 'Thongs', titleShort: 'Thong' },
	{ id: PRODUCT_TYPE_THONGS_MODAL, title: 'Modal Thongs', titleShort: 'Modal Thongs' },
	{ id: PRODUCT_TYPE_SOCKS_ANKLE, title: 'Socks', titleShort: 'Socks' },
	{ id: PRODUCT_TYPE_SOCKS_CREW, title: 'Socks', titleShort: 'Socks' },
	{ id: PRODUCT_TYPE_T_SHIRTS, title: 'Everyman Tee', titleShort: 'Everyman Tee' },
	{ id: PRODUCT_TYPE_WOMENS_BOXER, title: "Women's Boxer", titleShort: "Women's Boxer" },
];

const getProductTypes = () => {
	return PRODUCT_TYPES;
};

const getProductType = (id) => {
	return PRODUCT_TYPES.find((productType) => {
		return productType.id === id;
	});
};

module.exports = {
	PRODUCT_TYPE_BIKINI,
	PRODUCT_TYPE_BOXER,
	PRODUCT_TYPE_BOXER_BRIEF,
	PRODUCT_TYPE_BOXER_FLY,
	PRODUCT_TYPE_BOXER_LONG_FLY,
	PRODUCT_TYPE_BOXER_TRUNK,
	PRODUCT_TYPE_BOXERS,
	PRODUCT_TYPE_BOXERS_COOLING,
	PRODUCT_TYPE_BOXERS_BRIEF,
	PRODUCT_TYPE_BOXERS_FLY,
	PRODUCT_TYPE_BOXERS_LONG_FLY,
	PRODUCT_TYPE_BOXERS_TRUNK,
	PRODUCT_TYPE_BOYSHORT,
	PRODUCT_TYPE_CHEEKY,
	PRODUCT_TYPE_LAUNDRY_SHEETS,
	PRODUCT_TYPE_SOCKS_ANKLE,
	PRODUCT_TYPE_SOCKS_CREW,
	PRODUCT_TYPE_THONGS,
	PRODUCT_TYPE_THONGS_COOLING,
	PRODUCT_TYPE_THONGS_MODAL,
	PRODUCT_TYPE_WOMENS_BOXER,
	getProductType,
	getProductTypes,
};

import axios from 'axios';
import { formatProduct as formatProductAlgolia } from '/utils/product-normalizers/algolia';
import { parseGid } from '/utils/graph-ql';

const collections = {
	bikini: 'womens-bikini-underwear',
	bralettes: 'bralettes',
	'bralettes - cooling': 'cooling-thong-underwear-bralette',
	boxers: 'singles-ball-hammock-pouch-underwear',
	'boxers - cooling - fly': 'ball-hammock-pouch-cooling-underwear',
	'boxers - fly': 'mens-ball-hammock-pouch-underwear-with-fly',
	'boxers - long - fly': 'mens-long-leg-boxer-briefs',
	'boxers - trunk': 'ball-hammock-trunk-underwear',
	'boxers - brief': 'ball-hammock-briefs',
	"women's boxer": 'womens-boxer-briefs',
	boyshort: 'womens-boyshort-underwear',
	cheeky: 'cheeky-underwear',
	'long johns': 'long-john-underwear',
	"men's shorts": 'ball-hammock-athletic-shorts',
	'socks - ankle': 'heel-hammock-ankle-socks',
	'socks - crew': 'heel-hammock-crew-socks',
	thongs: 'womens-thong-underwear',
	'thongs - cooling': 'cooling-thong-underwear-bralette',
	'thongs - modal': 'modal-thong-underwear-for-women',
	"men's t-shirts": 'mens-everyman-t-shirts',
};

const validShopByPrintCollections = [
	'womens-bikini-underwear',
	'singles-ball-hammock-pouch-underwear',
	'ball-hammock-pouch-cooling-underwear',
	'long-john-underwear',
	'mens-ball-hammock-pouch-underwear-with-fly',
	'mens-everyman-t-shirts',
	'mens-long-leg-boxer-briefs',
	'modal-thong-underwear-for-women',
	'ball-hammock-trunk-underwear',
	'ball-hammock-briefs',
	'womens-boyshort-underwear',
	'cheeky-underwear',
	'womens-thong-underwear',
	'womens-boxer-briefs',
];

const getSimilarPrints = async (product, print, size) => {
	const formattedPrint = print?.includes('Solids') ? ' solid' : print?.replace('print:', '');
	formattedPrint?.replace('print:', '');
	const productType =
		product?.shopifyProductType?.toLowerCase() || product?.productType?.toLowerCase();
	let params = '?filters=';
	const paramsArr = [];

	let sizeFilter = '';

	if (print && productType != "Men's T-Shirts") {
		paramsArr.push(`tags%3A%22print:${formattedPrint.toLowerCase()}%22`);
	}

	if (productType) {
		paramsArr.push(`shopifyProductType%3A%22${productType}%22`);
	}

	if (size) {
		paramsArr.push(`_availableSizes.ecommerce%3A%22${size}%22`);
	}

	const formattedParams = paramsArr.join(' AND ');
	params += formattedParams;

	let res = await axios({
		method: 'get',
		url:
			`https://pipeline.shinesty.com/` +
			params +
			'&' +
			'facetFilters=%5B%22collections%3A' +
			(collections[productType] || 'guys-boxers') +
			'%22' +
			sizeFilter +
			'%5D&hitsPerPage=61',
	});

	let filteredArray = [];
	res.data.hits.map((result) => {
		if (product.productType == "Men's Shorts") {
			const length = product.handle.split('-shorts-');
			if (result.handle.includes(length && length.length > 0 ? length[1] : null)) {
				filteredArray.push(result);
			}
		} else {
			if (
				result.product_type == product.productType ||
				result.product_type == product.product_type
			) {
				filteredArray.push(result);
			}
		}
	});

	return filteredArray;
};

const getCollection = async (collectionHandle) => {
	const res = await axios({
		method: 'get',
		url: `https://pipeline.shinesty.com/?facetFilters=%5B%22collections%3A${String(
			collectionHandle,
		)}%22%5D&hitsPerPage=40`,
	});
	const formatted = [];

	if (res && res.data.hits) {
		res.data.hits.map((hit) => {
			if (hit) {
				formatted.push(formatProductAlgolia(hit));
			}
		});

		return { facets: {}, hits: formatted, nbPages: 1 };
	}
};

const getRecommendedProducts = async (sourceProduct) => {
	const res = await axios({
		method: 'get',
		url: `https://pipeline.shinesty.com/?facetFilters=%5B%22shopifyProductType%3A${sourceProduct.productType}%22%5D`,
	});

	let filteredArray = [];
	res.data.hits.map((result) => {
		if (result.handle != sourceProduct.handle) {
			filteredArray.push(result);
			result['id'] = parseGid(result.objectID);
		}
	});

	return filteredArray;
};

module.exports = {
	getCollection,
	getRecommendedProducts,
	getSimilarPrints,
	validShopByPrintCollections,
};

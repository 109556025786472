const SIZES_ALL = ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2X', '3X', '4X', '5X'];
const SIZES_MENS = ['XS', 'S', 'M', 'L', 'XL', '2X', '3X', '4X', '5X'];
const SIZES_MENS_SUBSCRIPTION = ['S', 'M', 'L', 'XL', '2X', '3X', '4X', '5X'];
const SIZES_MENS_SUBSCRIPTION_TEES = ['S', 'M', 'L'];
const SIZES_SOCKS = ['M', 'L'];
const SIZES_WOMENS = ['XS', 'S', 'M', 'L', 'XL', '2X'];

module.exports = {
	SIZES_ALL,
	SIZES_MENS,
	SIZES_MENS_SUBSCRIPTION,
	SIZES_MENS_SUBSCRIPTION_TEES,
	SIZES_SOCKS,
	SIZES_WOMENS,
};
